import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/react';

const ChakraIcon = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const handleNextClick = () => {
    ref.current.swiper.slideNext();
    setTimeout(() => {
      ref.current.swiper.autoplay.start();
    }, 500);
  };
  
  const handlePrevClick = () => {
    ref.current.swiper.slidePrev();
    setTimeout(() => {
      ref.current.swiper.autoplay.start();
    }, 500);
  };
  

  const name = props.name;
  const flag = props.flag;

  if (flag !== 1) {
    return (
      <IconButton
        ref={ref}
        aria-label="Next Slide"
        icon={name} 
        bg={theme.colors.secondText}
        color={theme.colors.text}
        onClick={handleNextClick}
        size={'lg'}
        zIndex={2}
        pos={'absolute'}
        top={{ base: "40%", md: "50%", lg: "50%" }}
        right={'0%'}
        borderRadius={'50%'}
        style={{
          transform: 'translateY(-50%)',
          boxShadow: `0px 0px 10px ${theme.colors.shadow}`,
        }}
        _hover={{
          bg: theme.colors.primary,
          color: 'black',
        }}
      />
    );
  } else {
    return (
      <IconButton
        ref={ref}
        aria-label="Previous Slide"
        icon={name}
        onClick={handlePrevClick}
        bg={theme.colors.secondText}
        color={theme.colors.text}
        size={'lg'}
        zIndex={2}
        pos={'absolute'}
        top={{ base: "40%", md: "50%", lg: "50%" }}
        left={'0%'}
        borderRadius={'50%'}
        style={{
          transform: 'translateY(-50%)',
          boxShadow: `0px 0px 10px ${theme.colors.shadow}`,
        }}
        _hover={{
          bg: theme.colors.primary,
          color: 'black',
        }}
      />
    );
  }
});

export default ChakraIcon;