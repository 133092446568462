import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    text: '#0A192F',
    secondText: '#FFFFFF',
    primary: '#E0F4FF',
    secondary: '#87C4FF',
    thirdly: '#39A7FF',
    shadow: 'rgb(190, 252, 255, 0.3)',
  },
  font: {
    primary: 'Helvetica Neue',
    secondary: 'Calibre'
  }
});

export default theme;