import {
  Box,
  chakra,
  Stack,
  HStack, Image,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react'
import { FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { ImBlog } from "react-icons/im";
import logo from '../../assets/Logo2.jpg';
import { useTheme } from '@chakra-ui/react';

const SocialButton = ({
  children,
  label,
  href,
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

export default function SmallWithLogoLeft() {
  const theme = useTheme();
  return (
    <Box
      bg={theme.colors.secondary}
      w={'full'}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Stack
      py={{ base: 2, md: 2, lg: 3, xl: 3}}
      px={2}
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: 4, md: 8 }}
      justify={{ base: 'center', md: 'space-between' }}
      align={{ base: 'center', md: 'center' }}
    >
      <HStack p={1}>
        <Image
          src={logo}
          alt='Arif Logo'
          h={{ base: '30px', md: '50px', lg: '60px' }}
        />
      </HStack>

      <Text
        color={theme.colors.text}
        overflow={'hidden'}
        wordBreak={'keep-all'}
        fontSize={{ base: 'md', sm: 'xl', lg: 'xl' }}
        overflowWrap={'break-word'}
      >
        © 2023 Arif Siddiqui. All rights reserved
      </Text>

      <Stack direction={'row'} spacing={6}>
        <SocialButton label={'Twitter'} href={'#'}>
          <FaTwitter />
        </SocialButton>
        <SocialButton
          label={'Linkedin'}
          href={'https://www.linkedin.com/in/arif-siddiqui-585799165/'}
        >
          <FaLinkedinIn />
        </SocialButton>
        <SocialButton
          label={'Instagram'}
          href={'https://www.instagram.com/arif.siddiqui_official/'}
        >
          <FaInstagram />
        </SocialButton>
        <SocialButton
          label={'Blog'}
          href={
            'https://arifnaseersiddiqui.blogspot.com/?fbclid=IwAR2XXElPpFpL0iCHLcaCAh5SN90xetSNiwLnRNfZTypFSr47nDyowbtRJ7g'
          }
        >
          <ImBlog />
        </SocialButton>
      </Stack>
    </Stack>
    </Box>
  )
}