import React from 'react'
import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    VStack,
    HStack,
    Stack,
    FormControl,
    FormLabel,
    Input,
    Select, 
  } from '@chakra-ui/react'
  import {
    MdEmail,
    MdLocationOn,
  } from 'react-icons/md'
  import '../../theme/Project.css'
  import { useTheme } from '@chakra-ui/react';
  import ContactMe from '../../assets/Contact.json';
  import { Player } from '@lottiefiles/react-lottie-player';


const Contact = () => {
    const theme = useTheme();
    const state = {
      button: 0
    }

    const EmailButtonClicked = (formValues) => {
      const { name, email, date, city } = formValues;

      const emailSubject = 'Inquiry about ' + city;
      const emailBody = `
          Name: ${name}
          Email: ${email}
          Date: ${date}
          City: ${city}
      `;

      const mailtoUrl = `mailto:contact@ariftourism.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
      window.open(mailtoUrl, "_blank");
  }

  const handleSubmit = (event) => {
    event.preventDefault();
        const formData = new FormData(event.target);
        const formValues = Object.fromEntries(formData.entries());
        
        console.log(formValues)
        
        if (state.button === 1) {
            EmailButtonClicked(formValues);
        } else  if (state.button === 2) {
            // WhatsappButtonClicked(formValues);
        }
  };

  return (
    <Container maxW="full" mt={0} centerContent overflow="hidden">
        <Flex>
        <Box
          bg="#02054B"
          color="white"
          w="full"
          borderRadius="lg"
          m={{ sm: 2, md: 16, lg: 10 }}
          p={{ sm: 1, md: 5, lg: 16 }}>
          <Stack p={2} direction={{ base: 'column', md: 'row' }}>
                <VStack display="flex"
                justifyContent="center"
                pt={1}
                alignItems="center">
                  <Heading>Contact</Heading>
                  <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                    Fill up the form below to contact
                  </Text>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack pl={0} spacing={3} alignItems="flex-start">
                      {/* <Button
                        size="md"
                        height="48px"
                        width="250px"
                        variant="ghost"
                        color="#DCE2FF"
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdPhone color="#1970F1" size="20px" />}>
                        +91 94159 51880
                      </Button> */}
                      <Button
                        size="md"
                        height="48px"
                        width="250px"
                        variant="ghost"
                        color="#DCE2FF"
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdEmail color="#1970F1" size="20px" />}>
                        contact@ariftourism.com
                      </Button>
                      <Button
                        size="md"
                        height="48px"
                        width="250px"
                        variant="ghost"
                        color="#DCE2FF"
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdLocationOn color="#1970F1" size="20px" />}>
                        Dubai, U.A.E
                      </Button>
                      <Player
                            autoplay
                            loop
                            src={ContactMe}
                            style={{
                            width: "100%",
                            maxWidth: "250px",
                            height: "auto",
                            }}
                        />
                    </VStack>
                  </Box>
                </VStack>

                <Box p={8} bg="white" borderRadius="lg">
                    <FormControl w={'100%'}>
                        <form onSubmit={handleSubmit}>
                            <VStack pt={5}>
                                <FormLabel color={theme.colors.text} >Name</FormLabel>
                                <Input 
                                variant='flushed'
                                size="lg"
                                color={theme.colors.text}
                                name="name"
                                placeholder="Enter Name"
                                />
                            </VStack>

                            <VStack pt={5}>
                                <FormLabel color={theme.colors.text} >Email</FormLabel>
                                <Input 
                                variant='flushed'
                                size="lg"
                                color={theme.colors.text}
                                name="email"
                                placeholder="Enter Email"
                                type="email"
                                />
                            </VStack>
                            
                            <VStack pt={5}>
                                <FormLabel color={theme.colors.text} >Date</FormLabel>
                                <Input 
                                variant='flushed'
                                size="lg"
                                color={theme.colors.text}
                                name="date"
                                placeholder="Select Date and Time"
                                type="date"
                                />
                            </VStack>

                            <VStack pt={5}>
                                <FormLabel 
                                    color={theme.colors.text} >City</FormLabel>
                                <Select placeholder='Select city' 
                                variant='flushed' size='lg' 
                                color={theme.colors.text}
                                name="city" isRequired >
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Dubai, United Arab Emirates</option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Bangkok, Thailand</option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Paris, France</option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >London, United Kingdom</option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Singapore</option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Kuala Lumpur, Malaysia</option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Istanbul, Turkey </option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Tokyo, Japan</option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Jeddah, Saudi Arabia</option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Delhi, India</option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Mumbai, India</option>
                                    <option style={{ color: theme.colors.text, padding: '2px' }} >Others</option>
                                </Select>
                            </VStack>

            
                            <HStack justifyContent={'center'} pt={10}>
                            <Button 
                                type="submit" 
                                bg={theme.colors.thirdly}
                                colors={theme.colors.text} 
                                color="white"
                                w="150px"
                                h="50px"
                                onClick={() => (state.button = 1)}
                                variant="solid"
                                name="buttonClicked"
                                value="email"
                                >
                                Email
                                </Button>
                            </HStack>

                        </form>
                        
                        </FormControl>
                  </Box>
          </Stack>
        </Box>
      </Flex>
    </Container>
  )
}

export default Contact