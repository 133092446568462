import React from 'react'
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react'
import { TypeAnimation } from 'react-type-animation';
import myPic from '../../assets/Profile2.jpg';
import MyImage from '../elements/Image'
import { useTheme } from '@chakra-ui/react';
import { Link } from 'react-scroll';

export default function Hero() {
    const theme = useTheme();

  return (
    <Container maxW={'7xl'}>
      <Stack   
        align={'center'}
        position={'relative'}
        spacing={{ base: 8, md: 10 }} px={{ base: 5, md: 10 }}
        direction={{ base: 'column', md: 'row' }}>
        
        <Stack flex={1}  >
          <Text color={theme.colors.primary} overflow={'hidden'}
                    wordBreak={'keep-all'}
                    fontSize={{ base: 'md', sm: 'xl', lg: 'xl' }}
                    overflowWrap={'break-word'}>
            Hello there! My name is 
          </Text>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
            <TypeAnimation
              sequence={[
                  "Arif Siddiqui",
                  2000, // Waits 1s
              ]}
              wrapper="span"
              cursor={false}
              style={{ fontSize: '42px', 
              color: '#39A7FF',
              display: 'inline-block', 
              fontFamily: 'Helvetica Neue',
              fontWeight: '600' 
                      }}
              />

          </Heading>
          
          <Text color={theme.colors.primary} overflow={'hidden'}
                    wordBreak={'keep-all'}
                    fontSize={{ base: 'md', sm: 'xl', lg: 'xl' }}
                    overflowWrap={'break-word'}>
            A seasoned tourism professional, with the specialization in providing all kind of travels services 
            across the world with personalization and and well connected travel solutions.
            <br></br>
            How can I assist you with your travel needs today?
          </Text>
          <Stack spacing={{ base: 4, sm: 6 }} py={2} direction={{ base: 'column', sm: 'row' }}>
          <Link to="contact" spy={true} 
                    smooth={true} offset={-70} 
                    duration={500}
                    >
            <Button
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              color={theme.colors.primary}
              bg={theme.colors.secondary}
              _hover={{ bg: theme.colors.thirdly }}>
              Contact Me
            </Button>
            </Link>
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
           <Box w={{ base: "100%", md: "100%", lg: "45%", xl: "45%" }} py={'2em'}
                display= "flex"
                justifyContent={'center'}
                alignItems={'center'} >
            <MyImage color={theme.colors.thirdly} imageUrl={myPic} maxWidth="100%" />
            
          </Box>
        </Flex>
      </Stack>
      
    </Container>
  )
}

