import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import WorkCard from '../elements/WorkCard'
import Logo1 from '../../assets/Logo1.png'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-flip";
import 'swiper/css/effect-cards';
import "swiper/css/navigation";
import "../../theme/Card.css"
import { Swiper, SwiperSlide, } from "swiper/react";
import { useTheme } from '@chakra-ui/react';
import { EffectCards } from 'swiper/modules';


const WorkExperienceMobile = () => {
  const theme = useTheme();

    const works = [
      {
        "title": "SAS Travel & Tourism Dubai",
        "date": "Nov 2022 - Present",
        "imageUrl": Logo1,
        "pos": "Operation Manager",
        "description": "S A S Travel & Tourism L.L.C is actively involved in UAE inbound and outbound tourism, Visit Visas, Air Ticketing, and Hotel Booking. Responsibilities include process and system management, maintaining relationships with clients and suppliers, liaising with internal departments, coordinating travel services, arranging accommodations, monitoring travel policies, and preparing reports."
      },
      {
        "title": "Sangam Travels Allahabad India",
        "date": "Oct 2015 - Jan 2021",

        "pos": "Senior Travels Consultant",
        "description": "Sangam Travels is active in International / Domestic Air Ticketing. Primary responsibilities included issuing international tickets and Tours packages, visa processing, providing opportunities to customers on details of travel such as weather, cost, destination, site-seeing, and training employees on various duties."
      },
      {
        "title": "Al Taif Tours Sharjah U.A.E",
        "date": "Jul 2013 - Mar 2014",
        "pos": "Ticketing / Travel Consultant",
        "description": "Al Taif Tours was active in UAE tourism, specializing in International Air Ticketing, inbound and outbound Tour packages, and Hotel Booking. Primary responsibilities included issuing Air tickets, marketing all travel services, selling specialized tours packages and vacation packages, and providing customers with details of travel such as weather, cost, and destination sightseeing."
      },
      {
        "title": "Mashreq Travel Service India",
        "date": "Jun 2010 - May 2012",
        "pos": "Branch Operation Manager",
        "description": "Mashreq Travel Service operated in Air Ticketing, Tour packages, Haj, Umra, and visa processing. Responsibilities included coordinating and directing merchandising travel services, selling specialized tours, carrier tickets, and vacation packages. Also involved in training new agents, planning work schedules, and providing travel advice to customers."
      },
      {
        "title": "Fourways Travel Pvt.Ltd. India",
        "date": "May 2007 - Nov 2009",
        "pos": "Reservation / Travel Consultant",
        "description": "Fourways Travels Pvt Ltd. was active in International / Domestic Air Ticketing and Tour packages. Responsibilities included issuing international/domestic tickets, marketing travel services, selling specialized tours, carrier tickets, and vacation packages. Involved in training new agents, planning work schedules, and providing travel advice to customers."
      },
      {
        "title": "Zaireen Travel Service",
        "date": "Sep 2004 - Aug 2006",
        "pos": "Visa / Reservation Supervisor",
        "description": "Zaireen was active in visa processing and International and Domestic Air Ticketing, Haj, Umra Tour packages. Primary responsibilities included visa processing, booking international & domestic tickets, dealing with new agents, and providing good service to customers with details of travel such as cost and destination."
      }
    ]

  return (
    <Box p={{ base: "1.5em", md: "1em", lg: "2em" }} align='center'
		w='100%'>
      <Heading fontSize={{ base: "32px", md: "36px", lg: "36px" }}
                    fontWeight={600}
                    align={'center'}
                    fontFamily={theme.font.secondary}
                    color={theme.colors.secondText}
                    py={'0.3em'} >Work Experience</Heading>	
		<Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="mobileSwiper" >
		{works.map((work, index) => (
            <SwiperSlide>
            <Box
                py={'1em'}
                color="white"
                zIndex="2"
                display= "flex"
                border = {'hidden'} 
                w={{base: '100%',md: '100%',lg: '100%',xl: '100%'}}
                justifyContent={'center'}
                alignItems={'center'}
                px={{base: '0em',md: '0.5em',lg: '0.5em'}}
            >
                <WorkCard
                    title={work.title}
                    date={work.date}
                    imageUrl={work.imageUrl}
                    pos={work.pos}
                    description={work.description}
                />
            </Box>
            </SwiperSlide>
        ))}
        </Swiper>
        </Box>
  );
};

export default WorkExperienceMobile;
