import {   
    Text, 
    Heading, 
    Stack, VStack,
    Show,
    Divider
} from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/react';
import Flight from '../../assets/Flight.json';
import { Player } from '@lottiefiles/react-lottie-player';


export default function About(){
    const theme = useTheme();
    return(
        <Stack bg={theme.colors.thirdly} w='100%' 
        direction={['column', 'row']}
        display= "flex"
        justifyContent={'center'}
        alignItems={'center'} 
         >
            <VStack w={{ base: "100%", md: "100%", lg: "50%", xl: "50%" }}
             p={{ base: "0.75em", md: "1em", lg: "1.5em" }}
             display= "flex"
            justifyContent={'center'}
            alignItems={'center'} >
                <Heading fontSize={{ base: "32px", md: "36px", lg: "36px" }}
                    fontWeight={600}
                    align={'center'}
                    fontFamily={theme.font.secondary}
                    color={theme.colors.text}
                    py={'0.3em'} >About Me.</Heading>

                <Text  fontSize={{ base: "16px", md: "20px", lg: "20px" }}
                    fontWeight={'semi-bold'}
                    overflow={'hidden'}
                    wordBreak={'keep-all'}
                    overflowWrap={'break-word'}
                    fontFamily={theme.font.secondary}
                    color={theme.colors.secondText}
                    px={'1.5em'}
                    py={'0.5em'}>
                    Hello there! My name is {' '}
                        <span style={{ color: '#0A192F' }}>Arif </span> 
                        I have over 14 years of experience in the travel and tourism industry, 
                        I've assisted individuals like yourself in turning their travel dreams into reality. 
                        I possess in-depth knowledge about numerous top travel destinations, 
                        specializing in organizing various types of trips such as Tours, Family trips, 
                        Adventure trips, Holiday trips, Umrah, Leisure trips, Cruise trips, and Medical trips. 
                       
                        <br></br> <br></br>
                        Additionally, I excel in handling flights, chartered bookings, 
                        corporate travel management, worldwide hotels, transportation, local attractions, 
                        and visa services.
                        <br></br>
                        <span style={{ color: '#0A192F' }}> Let's make those travel dreams come true! </span>
                </Text>
            </VStack>
            <Show breakpoint='(max-width: 400px)'
                ><Divider w={'50%'}  
                borderWidth="1px"
                borderColor={theme.colors.text}
                  /></Show>
            <VStack
                display="flex"
                justifyContent="center"
                spacing={10}
                alignItems="center"
                w={{ base: "100%", md: "100%", lg: "50%", xl: "50%" }}
                px={{ base: "1em", md: "2em", lg: "2em" }}
                py={{ base: "2em", md: "4em", lg: "4em" }}
                >
                <Player
                    autoplay
                    loop
                    src={Flight}
                    style={{
                    width: "100%",
                    maxWidth: "500px",
                    height: "auto",
                    }}
                />
                
                </VStack>
        </Stack>
    );
}