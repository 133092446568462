import {
  Box,
  Heading,
  Text,
  Stack,
  Avatar,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react'
import { useTheme } from '@chakra-ui/react';


export default function WorkCard(props ) {
    const theme = useTheme();
    const title = props.title;
    const description = props.description;
    const date = props.date;
    const pos = props.pos;
    const imageUrl = props.imageUrl;
  return (
      <Box
        py={{ base: 2, md: 4, lg: 5, xl: 6 }}
        w={{ base: "300px", md: "400px", lg: "450px", xl: "450px" }}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
    px={{ base: 1, md: 4, lg: 5, xl: 6 }}
    overflow={'hidden'}>
   <Box h={'20px'} bg={theme.colors.thirdly} mt={{base: -1 , md: -4, lg: -5, xl: -6}} 
      mx={{base: -1 , md: -4, lg: -5, xl: -6}}  mb={{base: 1 , md: 4, lg: 5, xl: 6}}  pos={'relative'}></Box>
    <Stack>
    
      <Heading
        color={useColorModeValue('gray.700', 'white')}
        fontSize={{ base: 'lg', md: '2xl', lg: '2xl', xl: '2xl' }}
        fontFamily={'body'}>
        {title}
      </Heading>
      <Stack my={{ base: 1, md: 2, lg: 3, xl: 3 }} 
      p={{ base: 1, md: 1, lg: 0, xl: 0 }}
      direction={'row'} spacing={4} align={'center'}>
        <Avatar src={imageUrl} />
        <Stack direction={'column'} spacing={0} fontSize={'sm'}>
          <Text color={theme.colors.text} fontWeight={600}>{pos}</Text>
          <Text color={'gray.500'}>{date}</Text>
        </Stack>
      </Stack>
      <Divider />
      <Box
        transition={'0.5s'}
        pos={'relative'}
        display="flex"
        justifyContent={'center'}
            overflow={'hidden'}
            w={{ base: "300px", md: "400px", lg: "400px", xl: "400px" }}
            h={{ base: "180px", md: "230px", lg: "230px", xl: "230px" }}
             >
          <Text fontSize={{ base: "12px", md: "16px", lg: "16px", xl: "16px"}}
              overflow={'hidden'}
              wordBreak={'keep-all'}
              overflowWrap={'break-word'}
              fontFamily={theme.font.primary}
              color={theme.colors.text}
              py={{ base: 1, md: 1, lg: 0, xl: 0 }}
              px={{ base: 2, md: 1, lg: 0.5, xl: 0.5 }}>
              {description}
            </Text>
        </Box>
      </Stack>
      <Box h={'20px'} bg={theme.colors.thirdly} mb={{base: -1 , md: -4, lg: -5, xl: -6}} 
      mx={{base: -1 , md: -4, lg: -5, xl: -6}}  mt={{base: 1 , md: 4, lg: 5, xl: 6}}  pos={'relative'}></Box>
  </Box>
  
  )
}