import React from 'react'
import {
    Box,
    Heading,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
  } from "@chakra-ui/react";

const AccordianCard = (props) => {

  const { title, description, color } = props
  return (
    <AccordionItem>
      <AccordionButton  >
        <Box as="span" flex='1' textAlign='left' >
          <Heading
            fontFamily={'Merriweather'}
            py={'0.2em'}
            color={color}
            fontSize={{base: 'sm',md: 'md',lg: '24px', xl: '28px'}} 
            fontWeight={400}>
            {title}
          </Heading>
        </Box>
        <AccordionIcon color="white" />
      </AccordionButton>
    <AccordionPanel pb={4}>
      <Heading fontFamily={'Merriweather'}
            py={'0.5em'}
            color={color}
            fontSize={{base: 'sm',md: 'md',lg: '20px', xl: '20px'}} 
            fontWeight={400}>
        {description}      
      </Heading>
    </AccordionPanel>
  </AccordionItem>
  )
}

export default AccordianCard