import { Box,  Image, Stack } from '@chakra-ui/react';
import Plane from "./Plane"


export default function MyImage({ imageUrl, color }) {
  return (
<Stack position="relative">
    <Box
        w={{ base: '300px', md: '350px', lg: '350px', xl: '500px' }}
        h={{ base: '300px', md: '350px', lg: '350px', xl: '500px' }}
        alignItems="center"
        p={{ base: 1, md: 2, lg: 4, xl: 4 }}
        justifyContent="center"
        zIndex='1'
        position="relative"
        borderRadius="15px"
        overflow="hidden"
    >
        <Image
            h="100%"
            zIndex={2}
            src={imageUrl}
            bgPosition="center"
            bgSize="cover"
            borderRadius="inherit"
        />
        <Plane />
    </Box>
</Stack>

  );
}