import {   
  Stack, 
} from '@chakra-ui/react';
import Flight from '../../assets/Plane.json';
import { Player } from '@lottiefiles/react-lottie-player';


export default function Plane(){
    return(
        <Stack w='100%' 
        direction={['column', 'row']}
        display= "flex"
        justifyContent={'center'}
        alignItems={'center'} 
        pos={'absolute'}
        top={'0'}
         >
            <Player
                autoplay
                loop
                src={Flight}
                style={{
                width: "100%",
                height: "auto",
                }}
            />
        </Stack>
    );
}