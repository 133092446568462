import { Button, 
    Flex, 
    HStack, 
    Show, 
    Spacer,
    Hide, 
    Box,
    VStack,
    Drawer, 
    DrawerBody, 
    DrawerHeader, 
    DrawerOverlay, 
    DrawerContent,
    DrawerFooter,
    DrawerCloseButton, 
    useDisclosure,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { useTheme } from '@chakra-ui/react';
import { Link } from 'react-scroll';
import Logo from '../elements/Logo';

export default function Header() {
    const theme = useTheme();
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        let prevScrollY = window.pageYOffset;
        const handleScroll = () => {
          const currentScrollY = window.pageYOffset;
          setIsSticky(currentScrollY > 0 && currentScrollY < prevScrollY);
          prevScrollY = currentScrollY;
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
      ;


    return (
        <Flex 
        w="100%"
        wrap="wrap"
        bg={theme.colors.text}
        h={'auto'}
        zIndex='5'
        boxShadow={isSticky ? `0 20px 50px ${theme.colors.shadow} ` : 'none'}
        position={isSticky ? 'fixed' : 'static'}
        top={isSticky ? 0 : 'auto'}
        color='white'
        as="header" 
        justifyContent={'flex-start'}>
            <HStack w={{base: '80%', md: '40%', lg: '40%'}} p={{base: '0', md: '1em', lg: '1em'}} >
                <Logo/>
            </HStack>
            <Spacer />
            <Show breakpoint='(max-width: 800px)'  >
                <Box p={'1em'}
                    px={'1em'}
                    w={{base: '20%', md: '20%', lg: '0'}}
                    display="flex"
                    justifyContent={'flex-end'}
                    alignItems="center"
                >
                    <MobileHeader />
                </Box>
            </Show>

            <Hide breakpoint='(max-width: 800px)'>
                <HStack
                    w={{base: '0', md: '40%', lg: '60%'}}
                    h={'4em'}
                    justifyContent={'center'} 
                    alignItems="center"
                    px={'8em'}
                    gap={'2em'}
                    pt={'2em'}
                    >
                    <Link to="hero" spy={true} 
                    smooth={true} offset={-70} 
                    duration={500} 
                    >
                        <Button 
                        color="white"
                        borderRadius={50}
                        size='lg'
                        border = {'hidden'}
                        colorScheme='teal'
                        bg = {theme.colors.secondary}
                        fontSize={'16px'}>
                            Home
                        </Button>
                    </Link>
                    <Link to="about" spy={true} 
                    smooth={true} offset={-70} 
                    duration={500}>
                        <Button 
                        // color="white"
                        borderRadius={50}
                        size='lg'
                        border = {'hidden'}
                        colorScheme='blue' 
                        variant='ghost'
                        fontSize={'16px'}>
                            About
                        </Button> 
                    </Link>
                    <Link to="education" spy={true} 
                    smooth={true} offset={-70} 
                    duration={500}
                    >
                        <Button 
                        size='lg'
                        borderRadius={50}
                        colorScheme='blue'  
                        variant='ghost'
                        border = {'hidden'}
                        fontSize={'16px'}>
                            Education
                        </Button>
                    </Link>
                    <Link to="experience" spy={true} 
                    smooth={true} offset={-70} 
                    duration={500} >
                        <Button 
                        // color="white"
                        size='lg'
                        borderRadius={50}
                        colorScheme='blue' 
                        variant='ghost'
                        border = {'hidden'}
                        fontSize={'16px'}>
                            Experience
                        </Button> 
                    </Link>
                </HStack>
            </Hide>
        </Flex>
  )
}

export function MobileHeader(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const theme = useTheme();

    return (
      <HStack w={'100%'}>
        <Link>
            <span ref={btnRef} colorScheme='teal' onClick={onOpen}>
                <GiHamburgerMenu color={theme.colors.primary} style={{fontSize: '28px'}}/>
            </span>
        </Link>
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
          closeOnOverlayClick={true}
        >
          <DrawerOverlay />
          <DrawerContent  
            bg={theme.colors.thirdly}
            color="white">
            <DrawerCloseButton />
            <DrawerHeader>Arif Tourism</DrawerHeader>
            <DrawerBody>
            <VStack color={'white'}
                    w="100%"
                    justifyContent={'center'} >
                    <Link to="hero" spy={true} 
                    smooth={true} offset={-70} 
                    duration={500}>
                        <Button  size='lg'
                        onClick={onClose}
                        borderRadius={50}
                        border = {'hidden'}
                        colorScheme={'white'}
                        variant='ghost'
                        fontSize={'18px'}>
                            Home
                        </Button>
                    </Link>
                    <Link to="about" spy={true} 
                    smooth={true} offset={-70} 
                    duration={500}>
                        <Button  size='lg'
                        borderRadius={50}
                        onClick={onClose}
                        border = {'hidden'}
                        colorScheme={'white'}
                        variant='ghost'
                        fontSize={'18px'}>
                            About
                        </Button> 
                    </Link>
                    <Link to="education" spy={true} 
                    smooth={true} offset={-70} 
                    duration={500}>
                        <Button  size='lg'
                        borderRadius={50}
                        onClick={onClose}
                        border = {'hidden'}
                        colorScheme={'white'} 
                        variant='ghost'
                        fontSize={'18px'}>
                            Education
                        </Button>
                    </Link>
                    <Link to="experience" spy={true} 
                    smooth={true} offset={-70} 
                    duration={500} >
                        <Button  size='lg'
                        borderRadius={50}
                        onClick={onClose}
                        border = {'hidden'}
                        colorScheme={'white'}
                        variant='ghost'
                        fontSize={'18px'}>
                            Experience
                        </Button> 
                    </Link>
                </VStack>
                
            </DrawerBody>
            <DrawerFooter bottom={'2'}>
                {/* <Contacts colors={theme.colors.secondText} /> */}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </HStack>
    )
  }