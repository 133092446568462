import React from "react";
import {
  Image,
  Stack,
  VStack,
  Box,
  Container,
  Heading,
  Accordion,
} from "@chakra-ui/react";
import image from '../../assets/Education1.png'
import AccordianCard from "../elements/AccordianCard";
import { useTheme } from '@chakra-ui/react';


const Education = () => {
    const theme = useTheme();
  return (
    <Container maxW={'7xl'}>
      <VStack w={'100%'}>
        <Stack p={{ base: "1em", md: "2em", lg: "3em" }} align="center" direction={["column", "row"]}>
            <VStack w={{ base: "100%", md: "50%", lg: "50%" }}>
            <Box>
                <Image
                  objectFit="contain"
                  borderRadius="md"
                  src={image}
                  alt="Image"/>
                          
            </Box>
            </VStack>
            <VStack w={{ base: "100%", md: "50%", lg: "50%" }} p="1em" align={'left'}>
              <Heading
                fontSize={{ base: "lg", md: "xl", lg: "42" }}
                fontWeight={600}
                color={theme.colors.primary}
                fontFamily={'Merriweather'}
              >
                Education
              </Heading>
              
              <Accordion>
              <AccordianCard 
                    title="IATA certificate (IATA)" 
                    description="Certificate given by IATA Training & Development Institute Canada in 2012."
                    color={theme.colors.thirdly}
                />
                <AccordianCard 
                    title="Master of Art (Arabic)" 
                    description="Completed Master of Art in Arabic from the University of Allahabad in 2006."
                    color={theme.colors.thirdly}
                />
                <AccordianCard 
                    title="Bachelor of Art (English lit. & Arabic)" 
                    description="Earned a Bachelor's degree in English literature and Arabic from the University of Allahabad in 2001."
                    color={theme.colors.thirdly}
                />
                <AccordianCard 
                    title="Galileo Training Program" 
                    description="Successfully completed the Galileo Training Program at Galileo Inter-global Delhi in 2008."
                    color={theme.colors.thirdly}
                />
                <AccordianCard 
                    title="Diploma in Web Designing" 
                    description="Attained a diploma in Web Designing from STG India in 2002."
                    color={theme.colors.thirdly}
                />
                <AccordianCard 
                    title="Windows & MS Office Diploma" 
                    description="Received a diploma in Windows & MS Office from Sristhi InfoTech India in 2002."
                    color={theme.colors.thirdly}
                />
                <AccordianCard 
                    title="Diploma in Computer Application" 
                    description="Completed a Diploma in Computer Application from Bitsoft in 1999."
                    color={theme.colors.thirdly}
                />
                    
              </Accordion>
            
              
            </VStack>
        </Stack>
      </VStack>
      </Container>
  )
}

export default Education