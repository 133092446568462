import React from 'react'
import {HStack, Image} from '@chakra-ui/react'
import logo from '../../assets/Logo.png';


const Logo = () => {
    return (
      <HStack >
        <Image  src={logo}
                alt='Arif Logo'
                h={{ base: '40px', md: '50px', lg: '80px' }}
            />
    </HStack>
    )
  }

export default Logo;