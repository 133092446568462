import React from 'react';
import { BrowserRouter as Router,
  //  Routes, Route
  } from 'react-router-dom';
import Headers from './components/templates/Headers'
import Footers from './components/templates/Footers';
import Hero from './components/templates/Hero' 
import About from './components/templates/About'
import WorkExperience from './components/templates/WorkExperience'
import WorkExperienceMobile from './components/templates/WorkExperienceMobile'
import Education from './components/templates/Education'
import Contact from './components/templates/Contact'
import { 
  HStack,
  VStack, Hide,
  Box, Divider, Show
} from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/react';

function App() {
  const theme = useTheme();
  return (
    <>
      <Router>
        <HStack bg={theme.colors.text} style={{overflowX: 'hidden'}} 
        w={{base: '100', md: '100%', lg: '100%', xl: '100%'}} >
          <VStack w={{base: '100', md: '100%', lg: '100%', xl: '100%'}} 
          >
            <Headers />
            <Box id="hero">
              <Hero/>
            </Box>
            <Box id="about">
              <About/>
            </Box>
            <Box id="education">
                <Education/>
            </Box>
            <Divider orientation='horizontal' w={'80%'} />
            <Hide  breakpoint='(max-width: 800px)'>
              <Box id="experience">
                  <WorkExperience/>
              </Box>
              <Divider orientation='horizontal' w={'80%'} />
            </Hide>
            <Show  breakpoint='(max-width: 800px)'>
              <Box id="experience">
                  <WorkExperienceMobile/>
              </Box>  
              <Divider orientation='horizontal' w={'80%'} />
            </Show>
            <Box id="contact">
                <Contact/>
            </Box>
            <Footers />
          </VStack>
        </HStack>      
      </Router>
      
    </>
  );
}

export default App;